import getNumberWithCommas from "./getNumberWithCommas";

export const getCartItemsTotalPrice = (items, type = "string") => {
  const total = items.reduce((acc, item) => {
    const { quantity, price, discAmountOnQty, discOnQty } = item;
    let discount = 0;
    if (discAmountOnQty && discOnQty && quantity >= discOnQty) discount = discAmountOnQty;
    return acc + (quantity * price) - discount
  }, 0);

  if (type === "string") return getNumberWithCommas(total);
  else return total
}