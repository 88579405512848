
const getCodeDiscountAmount = (totalPrice, discountCodeObj) => {
  if (!discountCodeObj.id) return 0;
  const { percentage, amount, min, max } = discountCodeObj;
  switch (discountCodeObj.type) {
    case "1":
      let discountAmount = (percentage * totalPrice / 100).toFixed(2);
      if (discountAmount > max) discountAmount = max;
      return discountAmount;
    case "2":
      return (percentage * totalPrice / 100).toFixed(2);
    case "3":
      if (totalPrice > min) return amount;
      return 0;
    case "4": return amount;
    default: return 0;
  }
}

export default getCodeDiscountAmount;