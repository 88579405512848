import React, { useEffect, useRef, useState } from "react";
import TempImage from "../../assets/ProductPage/smart_wifi_plug_2x.png";
import defaultImg from '../../assets/default-image.png';
import { getBlogImageUrl, getProductImageUrl, getSmURL } from "../../shared/getProductImageUrl";

const ProductImage = ({ src, alt = "", className, style, sm, blog }) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const firstTimeRenderRef = useRef(true);

  useEffect(() => {
    if (firstTimeRenderRef.current) {
      firstTimeRenderRef.current = false;
    } else {
      setIsLoaded(false);
    }
  }, [src]);

  const handleOnImageLoaded = () => {
    setIsLoaded(true);
  }

  let imageSrc = TempImage;
  if (src) {
    const imageURLHelper = blog ? getBlogImageUrl : getProductImageUrl;
    if (sm) {
      imageSrc = imageURLHelper(getSmURL(src));
    } else {
      imageSrc = imageURLHelper(src);
    }
  }
  return <>
    <img
      src={imageSrc}
      alt={alt}
      onLoad={handleOnImageLoaded}
      style={isLoaded ? { visibility: 'visible', ...style } : { visibility: 'hidden', width: 0, height: 0, ...style }}
      className={className}
    />
    {isLoaded
      ? null
      : <img src={defaultImg} alt="" className={className} style={style} />}
  </>;
};

export default ProductImage;
