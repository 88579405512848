import React, { useEffect, useState } from "react";
import CustomButton from "../Button";
import { Link, withRouter } from "react-router-dom";
import CartDropdownItem from "./CartDropdownItem";
import { useDispatch, useSelector } from "react-redux";
import { getCartItemsTotalPrice } from "../../shared/getCartItemsTotalPrice";
import InputWithLabel from "../InputWithLabel/InputWithLabel";
import getCodeDiscountAmount from "../../shared/getCodeDiscountAmount";
import { verifyCode } from "../../containers/CartPage/Cart.service";
import { Popover } from "@material-ui/core";
import getNumberWithCommas from "../../shared/getNumberWithCommas";

const CartDropdown = ({ cartItemsCount, history, cartAnchorEl, setCartAnchorEl, classes }) => {
  const { cartItems, shippingCostInfo } = useSelector((state) => state.cart);
  const [discountCode, setDiscountCode] = useState("");
  const dispatch = useDispatch();

  useEffect(() => {
    setDiscountCode(shippingCostInfo.discountCode.code)
  }, [shippingCostInfo]);

  const cartItemsContent = () => {
    return cartItems.map((item) => (
      <CartDropdownItem key={item.id} item={item} />
    ));
  };

  const subTotal = getCartItemsTotalPrice(cartItems, "number");
  const discount = getCodeDiscountAmount(subTotal, shippingCostInfo.discountCode)

  return (
    <Popover
      id='cart-dropdown'
      open={Boolean(cartAnchorEl)}
      anchorEl={cartAnchorEl}
      className={classes.cartPopover}
      classes={{ paper: classes.cartPopoverRoot }}
      onClose={() => setCartAnchorEl(null)}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
    >
      <div className="cart-dropdown">
        <div className="cart-dropdown__top-triangle" />
        <div className="cart-dropdown__content-container">
          {cartItems.length > 0 ? (
            <>
              <div className="cart-dropdown__content-top-section">
                <div className="cart-dropdown__items-count">
                  {cartItemsCount}
                  <span> items</span>
                </div>
                <Link className="cart-dropdown__cart-link" to="/cart" onClick={() => window.scrollTo(0, 0)}>
                  Visit Cart
              </Link>
              </div>
              <div className="cart-items">{cartItemsContent()}</div>

              <div className="summary-details">
                <div className="summary-details-row">
                  <div className="row-left">Sub Total:</div>
                  <div className="row-right">
                    PKR {getNumberWithCommas(subTotal)}
                  </div>
                </div>
                {shippingCostInfo.discountCode.id
                  ? <div className="summary-details-row">
                    <div className="row-left">Promotion Discount:</div>
                    <div className="row-right">
                      PKR -{getNumberWithCommas(discount)}
                    </div>
                  </div>
                  : null}
              </div>
              <div className="summary-details">
                <div className="summary-details-discount-heading">
                  Apply Discount Code
              </div>

                <div className="summary-details-apply">
                  <div className="summary-details-apply-input">
                    <InputWithLabel
                      placeholder="Enter code here"
                      noMarginBottom={true}
                      styles={{ marginBottom: 0 }}
                      value={discountCode}
                      setValue={setDiscountCode}
                    />
                  </div>

                  <CustomButton
                    onClick={() => dispatch(verifyCode(discountCode))}
                    className="button"
                    styles={{
                      alignSelf: "stretch",
                      borderTopLeftRadius: 0,
                      borderBottomLeftRadius: 0,
                      padding: "0rem 1rem",
                    }}
                  >
                    Apply Code
                </CustomButton>
                </div>
              </div>
              <CustomButton
                onClick={() => {
                  window.scrollTo(0, 0);
                  history.push("/customer-info/")
                }}
                styles={{
                  width: "100%",
                  marginTop: "1.6rem",
                  marginBottom: "1rem",
                }}
              >
                Checkout
            </CustomButton>
            </>
          ) : (
            <div className="cart-dropdown-empty-container">
              <div className="cart-dropdown-empty-text">
                There are currently no items in your cart
            </div>
            </div>
          )}
        </div>
      </div>
    </Popover>
  );
};

export default withRouter(CartDropdown);
