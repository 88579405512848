import React, { useState } from "react";
import Plus from "../../assets/ProductPage/plus.svg";
import Minus from "../../assets/ProductPage/minus.svg";

import CrossSvg from "../../assets/Cart/cross.svg";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import { useDispatch } from "react-redux";
import { decreaseCartItemQuantity, increaseCartItemQuantity, removeCartItem } from "../../containers/CartPage/Cart.action";
import { getItemTotalPrice } from "../../shared/getItemTotalPrice";
import showToast from "../../shared/showToast";
import ProductImage from "../ProductImage/ProductImage";

const CartDropdownItem = ({ item }) => {
  const dispatch = useDispatch();
  let { image, name, colorName, typeLabel, type, quantity, hex, id } = item;

  const [detailsToggle, setDetailsToggle] = useState(false);

  return (
    <div className="cart-item">
      <div className="cart-item-left">
        <div className="cart-item-image">
          <ProductImage src={image} sm />
        </div>
      </div>

      <div className="cart-item-right">
        <div className="cart-item-info">
          <div className="cart-item-heading-container">
            <div className="cart-item-heading">{name}</div>
            <div className="cart-item-cross" onClick={() => dispatch(removeCartItem({ id, hex, type: type.id }))}>
              <img src={CrossSvg} alt="" />
            </div>
          </div>

          <div
            className="cart-item-details-toggle"
            onClick={() => setDetailsToggle((val) => !val)}
          >
            Details{" "}
            <KeyboardArrowDownIcon
              style={{ transform: `rotate(${detailsToggle ? "0" : "180deg"})` }}
            />
          </div>

          <div
            className="cart-item-desc"
            style={detailsToggle ? { height: "5rem" } : { height: "0" }}
          >
            <div className="cart-item-color">
              Color: <span className="name">{colorName}</span>
              <span className="dot" style={{ backgroundColor: hex }}></span>
            </div>
            <div className="cart-item-type">
              {typeLabel}: <span>{type.value}</span>
            </div>
          </div>

          <div className="cart-item-quantity-box">
            <div className="product__row-counter">
              <div
                className="product__row-counter-btn"
                onClick={() => quantity > 1 && dispatch(decreaseCartItemQuantity({ id, hex, type: type.id }))}>
                <img src={Minus} alt="" />
              </div>
              <div className="product__row-counter-text">{quantity}</div>

              <div
                className="product__row-counter-btn"
                onClick={() => {
                  const { quantity, discAmountOnQty, discOnQty, availableQuantity } = item;
                  if (discAmountOnQty && discOnQty && quantity + 1 === +discOnQty) {
                    showToast("Congrats", `Congratulations! By adding ${discOnQty} Items you save Rs. ${discAmountOnQty}`, true, "countDiscount");
                  }
                  if (quantity >= availableQuantity) {
                    showToast(
                      "Error",
                      "Sorry, Selected quantity is not available",
                      true,
                      "quantityError"
                    );
                    return;
                  }
                  dispatch(increaseCartItemQuantity({ id, hex, type: type.id }))
                }}
              >
                <img src={Plus} alt="" />
              </div>
            </div>
            <div className="product__row-price">
              Rs. {getItemTotalPrice(item)}
            </div>
          </div>
        </div>
      </div>
    </div >
  );
};

export default CartDropdownItem;
