import React from "react";
import "./input-with-label.scss";

const InputWithLabel = ({
  label,
  placeholder,
  value,
  setValue,
  required,
  type = "text",
  noMarginBottom,
  error = '',
  disabled,
  noOfLines = 1,
  styles,
}) => {
  return (
    <div className="input-with-label-container" style={styles}>
      {label && (
        <div className="input-label">
          {label} {required && <span className="required">*</span>}
        </div>
      )}
      <div
        className="input-container"
        style={noOfLines > 1 ? { height: "unset" } : {}}
      >
        {noOfLines > 1 ? (
          <textarea
            type={type}
            disabled={disabled}
            rows={noOfLines}
            className="input"
            placeholder={placeholder}
            value={value}
            onChange={(e) => setValue(e.target.value)}
          />
        ) : (
          <input
            type={type}
            className="input"
            placeholder={placeholder}
            disabled={disabled}
            value={value}
            onChange={(e) => setValue(e.target.value)}
          />
        )}
      </div>
      {error && <div className="error-text">{error}</div>}
    </div>
  );
};

export default InputWithLabel;
